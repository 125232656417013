<template>
  <v-dialog
    v-model="showWarning"
    max-width="500"
    content-class="profile-confirm-dialog"
    persistent
  >
    <v-card id="profile-confirm" style="background-color: #111111; border-radius: 8px !important; border: 1px solid #b1b2b3;">
      <v-card-title class="justify-content-center" style="background-color: #111111; color: #fff; font-weight: bold; padding-top: 36px; text-transform: unset;">Session Timeout</v-card-title>
      <v-card-text style="margin-top: 0;"
        ><v-row no-gutters>
          <v-col cols="12" style="padding: 10px 20px 0;">
            <div class="text-center" style="color: #fff;">
              <span>You have been logged out due to inactivity.</span>
            </div>
          </v-col>
          <v-col cols="12" class="text-center" style="padding: 30px 0 16px;">
            <button
              class="btn btn-custom btn_black_close"
              :ripple="false"
              @click="logout"
            >
              OK
            </button>
          </v-col>
        </v-row></v-card-text
      >
    </v-card>
  </v-dialog>
</template>
<script>
import { auth } from "@/services";

export default {
  data() {
    return {
      showWarning: false,
      timeoutId: null,
      sessionTimeout: 60 * 60 * 1000,
    };
  },
  mounted() {
    window.addEventListener("mousemove", this.resetTimeout);
    window.addEventListener("keydown", this.resetTimeout);
  },
  methods: {
    resetTimeout() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.showWarning = true;
        sessionStorage.removeItem('token');
        sessionStorage.removeItem("user");
      }, this.sessionTimeout);
    },
    logout() {
      auth.logout().finally(() => {
        this.$store.dispatch("logout").then(() =>  window.location.href = "/login");
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.resetTimeout);
    window.removeEventListener("keydown", this.resetTimeout);
  },
};
</script>

<style scoped>
  .btn_black_close{
    color: #fff; 
    background-color: #010101;
    border-color: #b1b2b3;
  }

  .btn_black_close:hover{
    background-color: #292929;
  }
</style>
