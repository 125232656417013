<template>
 <v-app :class="appWrapperClasses">    
  <BarMenu v-if="$store.state && $store.state.user && $store.state.token && !noAuthRoutes.includes($route.name)"></BarMenu>
    <v-main style="padding: 0">
      <v-container fluid class="p-0">
        <router-view />
        <SessionTimeoutWarning v-if="showSessionTimeoutWarning" />
        <BrowserWarning />
        <div class="clearfix"></div>
      </v-container>
    </v-main>
    <FooterComp v-if="!noAuthRoutes.includes($route.name)"/>
  </v-app>
</template>

<style src="./assets/css/styles.css"></style>

<script>
import BarMenu from "@/components/BarMenu.vue";
import FooterComp from "@/components/FooterComp";
import { noAuthRoutes } from '@/constants';
import SessionTimeoutWarning from "@/components/SessionTimeoutWarning.vue";
import BrowserWarning from "@/components/BrowserWarning.vue";

export default {
  name: "App",
  data() {
    return {
      noAuthRoutes: noAuthRoutes,
      showSessionTimeoutWarning: false,
    };
  },
  computed: {
    appWrapperClasses() {
      const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

      return {
        'app-wrapper': true,
        'isTouchScreen': isTouchScreen,
        'isNonTouchScreen': !isTouchScreen
      };
    },
  },
  components: { BarMenu, FooterComp, SessionTimeoutWarning, BrowserWarning, },
  watch:{
    "$route.name": function(){
      setTimeout(()=>{
        if(this.$route.name !== "Login" && this.$route.name !== "Forgot Password"){
          this.showSessionTimeoutWarning = true;
        }
      }, 500)
    }
  },
};
</script>
