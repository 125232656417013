<template>
  <footer FooterComp v-if="isLoggedIn">
    <div class="d-flex justify-content-center footer-content">
      <span>Powered by Panasonic</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  data() {
    return {
      ktc_version: "Smart Lighting Solution Ver.2.0.0 ©2023",
    };
  },
  async created() {
    // this.info_footer = await this.getVersion();
  },
};
</script>

<style scoped>
.footer-content {
  padding-top: 10px;
  padding-bottom: 12px;
}
.footer-content span {
  font-family: 'PUDSansserifM';
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  .der-footer {
    font-size: 14px;
  }
}

@media only screen and (max-width: 820px) {
  .footer-content span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-content span {
    font-size: 10px;
  }
}
</style>
