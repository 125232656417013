const data = [
  {
    id: "ISC_IS1",
    group: "Indoor Show Group",
    name: "IS1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD BCAST",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IS2",
    group: "Indoor Show Group",
    name: "IS2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD BCAST",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "12",
    power_consumption: 0,
  },
  {
    id: "ISC_IS3",
    group: "Indoor Show Group",
    name: "IS3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD BCAST",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "",
    power_consumption: 0,
  },
  {
    id: "ISC_IP1",
    group: "Indoor Practice Group",
    name: "IP1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP2",
    group: "Indoor Practice Group",
    name: "IP2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP3",
    group: "Indoor Practice Group",
    name: "IP3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_IP4",
    group: "Indoor Practice Group",
    name: "IP4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_01",
    group: "Outdoor Tennis Group",
    name: "O1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_02",
    group: "Outdoor Tennis Group",
    name: "O2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_03",
    group: "Outdoor Tennis Group",
    name: "O3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_04",
    group: "Outdoor Tennis Group",
    name: "O4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "15",
    power_consumption: 0,
  },
  {
    id: "ISC_05",
    group: "Outdoor Tennis Group",
    name: "O5",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_06",
    group: "Outdoor Tennis Group",
    name: "O6",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_07",
    group: "Outdoor Tennis Group",
    name: "O7",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_08",
    group: "Outdoor Tennis Group",
    name: "O8",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 0,
    expected_on: 1,
    scheduled: 1,
    online: 0,
    led: "",
    power_consumption: 0,
  },
  {
    id: "ISC_J1",
    group: "Junior Group",
    name: "J1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_J2",
    group: "Junior Group",
    name: "J2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 0,
    led: "",
    power_consumption: 0,
  },
  {
    id: "ISC_R1",
    group: "Rooftop Group",
    name: "R1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R2",
    group: "Rooftop Group",
    name: "R2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R3",
    group: "Rooftop Group",
    name: "R3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
  {
    id: "ISC_R4",
    group: "Rooftop Group",
    name: "R4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "CLASS II",
    expected_mode: "",
    on: 1,
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
  },
];

const indoor_show_group = [
  {
    id: "ISC_IS1",
    group: "Indoor Show Group",
    name: "IS1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD BCAST",
    expected_mode: "HD BCAST",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR", "HD BCAST"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "ISC_IS1",
  },
  {
    id: "ISC_IS2",
    group: "Indoor Show Group",
    name: "IS2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD BCAST",
    expected_mode: "HD BCAST",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR", "HD BCAST"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "ISC_IS2",
  },
  {
    id: "ISC_IS3",
    group: "Indoor Show Group",
    name: "IS3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "HD BCAST",
    expected_mode: "HD BCAST",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR", "HD BCAST"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "ISC_IS3",
  },
];

const indoor_practice_group = [
  {
    id: "ISC_IP1",
    group: "Indoor Practice Group",
    name: "IP1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "IPC_IP1",
  },
  {
    id: "ISC_IP2",
    group: "Indoor Practice Group",
    name: "IP2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "IPC_IP2",
  },
  {
    id: "ISC_IP3",
    group: "Indoor Practice Group",
    name: "IP3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "IPC_IP3",
  },
  {
    id: "ISC_IP4",
    group: "Indoor Practice Group",
    name: "IP4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "COM",
    expected_mode: "COM",
    on: 1,
    modes: ["COM", "CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "IPC_IP4",
  },
];

const outdoor_tennis_group = [
  {
    id: "ISC_01",
    group: "Outdoor Tennis Group",
    name: "O1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_01",
  },
  {
    id: "ISC_02",
    group: "Outdoor Tennis Group",
    name: "O2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_02",
  },
  {
    id: "ISC_03",
    group: "Outdoor Tennis Group",
    name: "O3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_03",
  },
  {
    id: "ISC_04",
    group: "Outdoor Tennis Group",
    name: "O4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_04",
  },
  {
    id: "ISC_05",
    group: "Outdoor Tennis Group",
    name: "O5",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_05",
  },
  {
    id: "ISC_06",
    group: "Outdoor Tennis Group",
    name: "O6",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_06",
  },
  {
    id: "ISC_07",
    group: "Outdoor Tennis Group",
    name: "O7",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_07",
  },
  {
    id: "ISC_08",
    group: "Outdoor Tennis Group",
    name: "O8",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 0,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "OTC_08",
  },
];

const junior = [
  {
    id: "ISC_J1",
    group: "Junior Group",
    name: "J1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "15",
    power_consumption: 0,
    court_id: "JC_J1",
  },
  {
    id: "ISC_J2",
    group: "Junior Group",
    name: "J2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "JC_J2",
  },
];

const rooftop_group = [
  {
    id: "ISC_R1",
    group: "Rooftop Group",
    name: "R1",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "RC_R1",
  },
  {
    id: "ISC_R2",
    group: "Rooftop Group",
    name: "R2",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "RC_R2",
  },
  {
    id: "ISC_R3",
    group: "Rooftop Group",
    name: "R3",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "RC_R3",
  },
  {
    id: "ISC_R4",
    group: "Rooftop Group",
    name: "R4",
    number_of_lights: 22,
    dimming: 100,
    expected_dimming: 100,
    mode: "TOUR",
    expected_mode: "TOUR",
    on: 1,
    modes: ["CLASS II", "TOUR"],
    expected_on: 1,
    scheduled: 1,
    online: 1,
    led: "OK",
    power_consumption: 0,
    court_id: "RC_R4",
  },
];

const courts_data = {
  data,
  indoor_show_group,
  indoor_practice_group,
  outdoor_tennis_group,
  junior,
  rooftop_group,
};

export default courts_data;
