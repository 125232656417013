import axios from 'axios';
import store from '../store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API + "/api/v1",
  timeout: 5000
});
const excludeRedirectRoute = ["/login", "users/profile/update-password"];

if (sessionStorage.getItem('token')) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
  instance.defaults.headers.common["Content-Type"] = "application/json";
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      // sessionStorage.removeItem("user");
      // sessionStorage.removeItem("token");
      // store.commit('setNetworkError', true);
      // store.commit('setErrorBrowser', error);
      return Promise.reject(new Error("Network error occurred. Please check your internet connection."));
    }
    if (error.response && error.response.status === 401) {
      if(!excludeRedirectRoute.includes(error.config.url)){
        const doubleSessionError = error.response.data.message;
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        doubleSessionError == "Unauthorized" ? "": sessionStorage.setItem('doubleSessionMsg',doubleSessionError);
        window.location.href = "/login";
      }
    }

    if (error.response && error.response.status >= 500) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      store.commit('setErrorBrowser', "Internal server error.");
    }
    return Promise.reject(error);
  }
);

export default {
  instance,
  get(url, params) {
    return instance.get(url, params);
  },
  post(url, params) {
    return instance.post(url, params);
  },
  put(url, params) {
    return instance.put(url, params);
  },
  delete(url, params) {
    return instance.delete(url, {data: JSON.stringify(params)});
  }
}