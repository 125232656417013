<template>
  <div id="profile-wrapper">
    <v-row no-gutters>
      <v-col cols="12" class="main-content">
        <div>
          <v-row id="top-form" class="box-height1" no-gutters>
            <v-col cols="6" class="first-col">
              <v-row class="fill-height" no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p>Username : {{ userName }}</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-3">
                  <p>Password : <span class="hidden-pass"  style="vertical-align: middle; position:relative; padding:0">••••••••</span></p>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" class="second-col" style="top: 20px">
              <v-col cols="12">
                <v-col class="text-right btn-change-password pr-0">
                  <v-btn
                    :ripple="false"
                    class="form-btn"
                    style="height: 40px; width: auto"
                    @click="modalChangePassword = true"
                  >
                    Change Password
                  </v-btn>
                </v-col>
              </v-col>
            </v-col>

            <v-col cols="12 pt-4" class="second-col">
              <v-row no-gutters class="text-right">
                <v-col cols="12">
                  <button
                    class="btn btn-secondary btn-custom"
                    style="margin-right: 10px;"
                    :ripple="false"
                    @click="closeProfile()"
                  >
                    Cancel
                  </button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button
                class="btn btn-primary btn-custom"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <v-dialog 
      v-if="modalChangePassword"
      v-model="modalChangePassword"
      max-width="500"
      content-class="profile-confirm-dialog"
      persistent
    >
      <v-card id="profile-confirm" class="dialog-change-password">
        <v-card-title class="justify-center">Change Password</v-card-title>
        <v-card-text 
          >
          <v-form
            ref="formChangePassword"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters class="fill-height">
                  <v-col cols="12">
                    <v-row no-gutters class="half-height">
                      <v-col cols="12">
                        <v-row no-gutters class="justify-center"><p>Current Password:</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                              class="my-profile"
                              v-model="oldPassword"
                              required
                              :type="showOldPass ? 'text' : 'password'"
                              autocomplete="off"
                              :rules="oldPasswordRules"
                              outlined
                              filled
                              placeholder="Enter Current Password"
                              @input="handleInputCurrentPassword"
                          >
                          </v-text-field>
                        </v-row>
                      </v-col>
                      <v-col cols="12 pt-1">
                        <v-row no-gutters class="justify-center"><p>New Password:</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            class="my-profile"
                            v-model="newPassword"
                            required
                            :type="showNewPass ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="newPasswordRules"
                            outlined
                            filled
                            placeholder="New Password"
                          >
                          </v-text-field>
                        </v-row>
                      </v-col>
                      <v-col cols="12 pt-1">
                        <v-row no-gutters class="justify-center"><p>Confirm Password:</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            class="my-profile"
                            v-model="ConfirmPassword"
                            required
                            :type="showConfirmPassword ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="confirmPassRules"
                            outlined
                            filled
                            placeholder="Confirm Password"
                          >
                          </v-text-field>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12 pt-4" class="text-right">
                <button
                  class="btn btn-secondary btn-custom"
                  type="button"
                  @click="closeChangePasswordDialog"
                >
                  Cancel
                </button>
                <button
                  class="ml-4 btn btn-primary btn-custom"
                  type="submit"
                >
                  Save
                </button>
              </v-col>
            </v-row>
          </v-form>
          </v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" src="@/assets/css/user-profile.scss"></style>
<script>
import moment from "moment";
import { userProfileService } from "../services";
export default {
  data() {
    return {
      userName: "",
      userGroup: "",
      userGroupId: "",
      userSite: "",
      lastLogin: "",
      oldPassword: "",
      newPassword: "",
      ConfirmPassword: "",
      dialog: false,
      showOldPass: false,
      showNewPass: false,
      showConfirmPassword: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      passwordUpdated: false,
      newPasswordRules: [
        (v) => {
        
          if (!v) return "Password is required";
          if (v.length < 8 || v.length > 10 || !/(?=.*[A-Z])/.test(v) || !/(?=.*[!@#$%^&+=*_-])/.test(v) || !/(?=.*\d)/.test(v)) return "New password must contain 8-10 characters with at least 1 special character & 1 uppercase letter and 1 number.";
          return true;
        }
      ],
      userProfile: {},
      valid: false,
      editValid: false,
      fileRule: [
        (value) =>
          !value ||
          value.size <= 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      modalChangePassword: false,
      timeoutCheckPassword: null,
      messageInvalidPassword: false,
    };
  },
  async created() {
    await userProfileService
      .getCurrentUserProfile()
      .then((res) => {
        if (res.status == 200 && res?.data?.data) {
          this.setUserProfile(res.data.data);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          this.apiMessage = err.response.data.message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        }
      });
  },
  computed: {
    confirmPassRules() {
      return [
        this.ConfirmPassword === this.newPassword || "Passwords do not match",
      ];
    },
    oldPasswordRules() {
      return [
        !this.messageInvalidPassword || "Incorrect password entered. Please try again",
      ];
    }
  },
  methods: {
    setUserProfile(data) {
      this.userName = data.username || "";
      this.userGroup = data.user_group?.name || "";
      this.userGroupId = data.user_group_id || "";
      this.userSite = data.user_site || "N/A";
      this.valid = !!data.email;
      this.lastLogin = data.last_login
        ? moment(data.lastLogin).format("DD-MM-YYY HH:mm:ss")
        : "Active Now";
      this.userProfile = Object.assign({}, this.userProfile, data);
    },
    revert() {
      this.setUserProfile(this.userProfile);
      this.showOldPass = false;
      this.showNewPass = false;
      this.showConfirmPassword = false;
    },
    submitUpdate() {
      const data = {
        username: this.userName,
        user_group_id: this.userGroupId,
      };
      return userProfileService
        .setUserProfile(data)
        .then((res) => {
          if (res.status == 200 && res?.data?.data) {
            this.setUserProfile(res.data.data);
            this.apiMessage = "Update profile user successfully";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.$emit("submit-success");
          } else {
            throw res;
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.apiMessage = err.response.data.message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          }
        });
    },
    triggerFileChange() {
      this.$refs.file.$refs.input.click();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.editForm.reset();
    },
    handleInputCurrentPassword() {
      if (this.timeoutCheckPassword) {
        clearTimeout(this.timeoutCheckPassword);
      }
      this.timeoutCheckPassword = setTimeout(() => {
        this.checkValidCurrentPassword();
      }, 500);
    },
    checkValidCurrentPassword() {
      if(this.oldPassword.length < 8 || this.oldPassword.length > 10) {
        this.messageInvalidPassword = true;
      } else {
        const data = {
          current_password: this.oldPassword,
          is_check_password: 1,
        };
        userProfileService
          .updateUserPassword(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.messageInvalidPassword = false;
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              this.messageInvalidPassword = true;
            }
          });
      }
    },
    submitEdit() {
      if (this.$refs.formChangePassword.validate()) {
        const data = {
          current_password: this.oldPassword,
          new_password: this.newPassword,
        };
        let vm = this;
        userProfileService
          .updateUserPassword(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            vm.revert();
            vm.apiMessage = "Update password successfully.";
            vm.apiTitle = "Success";
            vm.messageDialog = true;
            vm.modalChangePassword = false;
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              this.apiMessage = err.response.data.message;
              this.apiTitle = "Error";
              this.messageDialog = true;
            }
            vm.modalChangePassword = false;
          });
      }
    },
    closeMessage() {
      this.messageDialog = false;
      this.$refs.formChangePassword.reset();
    },
    closeProfile() {
      this.$emit("submit-success");
    },
    closeChangePasswordDialog(){
      this.$refs.formChangePassword.reset();
      this.modalChangePassword = false;
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
