<template>
  <v-dialog
    v-if="errorBrowser"
    v-model="messageDialog"
    max-width="500"
    content-class="profile-confirm-dialog"
    persistent
  >
    <v-card id="profile-confirm" class="dialog_content">
      <v-card-title style="background-color: #111111;"></v-card-title>
      <v-card-text
        ><v-row no-gutters>
          <v-col cols="12" class="message_content">
            <div> {{ errorBrowser }} </div>
          </v-col>
          <v-col cols="12" class="close_btn_content">
            <button
              class="btn btn-primary btn_black"
              :ripple="false"
              @click="closeMessage"
            >
              OK
            </button>
          </v-col>
        </v-row></v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    errorBrowser() {
      return this.$store.state.errorBrowser;
    },
  },
  data() {
    return {
      messageDialog: true,
    };
  },
  methods: {
    closeMessage() {
      this.messageDialog = false;
      this.errorBrowser = null;
      sessionStorage.removeItem("doubleSessionMsg");
      if (location.pathname != "/login") {
        location.reload();
      }
    },
  },
};
</script>

<style scoped>
  #profile-confirm.dialog_content{
    background-color: #111111; 
    border-radius: 8px !important; 
    border: 1px solid #b1b2b3;
  }

  #profile-confirm.dialog_content .v-card__title{
    padding: 12px 24px;
  }

  #profile-confirm.dialog_content .v-card__text{
    margin-top: 0;
    padding: 0 24px 24px;
  }

  #profile-confirm.dialog_content .v-card__text .message_content{
    padding-left: 30px; 
    padding-right: 30px; 
    text-align: center;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content{
    text-align: center;
    padding-top: 40px;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content .btn_black{
    background-color: #010101;
    border-color: #b1b2b3;
    border-radius: 5px;
    padding: 8px 16px;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content .btn_black:hover{
    background-color: #292929;
  }
</style>
