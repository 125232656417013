import moment from "moment";
import { auth } from "../services/";
import store from '../store/index.js';

export default {
  install: (app) => {
    app.mixin({
      data: function () {
        return {
          DATE_TIME_HOUR_FORMAT_MOMENT: "YYYY-MM-DD HH:mm",
          FULL_DATE_TIME_FORMAT_MOMENT: "YYYY-MM-DD HH:mm:ss",
          DATE_FORMAT_SCHEDULE: "YYYY-MM-DD",
          HOUR_FORMAT: "HH:mm",
          COLOR_SCHEDULE_ONGOING: "#FCD5B5",
          COLOR_SCHEDULE_PENDING: "#F79646",
          MAX_DAY_SELECT: 90,
          MIN_MONTH_SELECT: 36,
          GROUP_NAME: {
            "Indoor Show Group": "indoor_show_group",
            "Indoor Practice Group": "indoor_practice_group",
            "Outdoor Tennis Group": "outdoor_tennis_group",
            "Junior Group": "junior",
            "Rooftop Group": "rooftop_group",
          },
          STATUS_DISABLED_DATE_SCHEDULE: ['On-going', 'Completed', 'Pending'],
          HIGH_LIGHT_HOUR: [],
          TIMEOUT_UPDATE_COURT: 10000,
          SUPER_ADMIN_ROLE: 'Superadmin'
        };
      },
      methods: {
        Empty: function (value) {
          return (
            value == undefined ||
            value == null ||
            value == "" ||
            value.length === 0 ||
            Object.keys(value).length === 0
          );
        },
        MOMENT: function (dateTime, format) {
          if (typeof format !== "undefined" && format) {
            return moment(dateTime).format(format);
          }
          return moment(dateTime);
        },
        NOW_DATE: function () {
          return moment();
        },
        handelGroupModes(data) {
          let dataGroup = {
            "Indoor Show Group": [],
            "Indoor Practice Group": [],
            "Outdoor Tennis Group": [],
            "Junior Group": [],
            "Rooftop Group": [],
          };
          for (var item of data) {
            if (item.group == "Indoor Show Group") {
              dataGroup["Indoor Show Group"].push(item.name);
            } else if (item.group == "Indoor Practice Group") {
              dataGroup["Indoor Practice Group"].push(item.name);
            } else if (item.group == "Outdoor Tennis Group") {
              dataGroup["Outdoor Tennis Group"].push(item.name);
            } else if (item.group == "Junior Group") {
              dataGroup["Junior Group"].push(item.name);
            } else if (item.group == "Rooftop Group") {
              dataGroup["Rooftop Group"].push(item.name);
            }
          }
          return dataGroup;
        },
        handelGroupCourts(group_mode, courts) {
          let dataGroup = {
            indoor_show_group: [],
            indoor_practice_group: [],
            outdoor_tennis_group: [],
            junior: [],
            rooftop_group: [],
          };
          for (var item of courts) {
            item.modes = group_mode[item.group];
            if (item.group == "Indoor Show Group") {
              dataGroup.indoor_show_group.push(item);
            } else if (item.group == "Indoor Practice Group") {
              dataGroup.indoor_practice_group.push(item);
            } else if (item.group == "Outdoor Tennis Group") {
              dataGroup.outdoor_tennis_group.push(item);
            } else if (item.group == "Junior Group") {
              dataGroup.junior.push(item);
            } else if (item.group == "Rooftop Group") {
              dataGroup.rooftop_group.push(item);
            }
          }
          return dataGroup;
        },
        renderIcon(show, tag, classShow, classElse) {
          return `<${tag} class='${show ? classShow : classElse}'></${tag}>`;
        },
        renderIconDropdown(tag, classIcon) {
          return `<${tag} class='${classIcon}'></${tag}>`;
        },
        async getVersion() {
          let version = "Smart Lighting Solution Ver.2.0.0 ©2022";
          await auth.getVersion().then((res) => {
            if (res.status == 200 && res.data.success) {
              version =
                "Smart Lighting Solution Ver." +
                res.data.data +
                " ©" +
                moment().format("Y");
            }
          });
          return version;
        },
        getDaysBetween(start, end) {
          var now = start.clone(),
            dates = [];
          while (now.isSameOrBefore(end)) {
            dates.push(now.clone());
            now.add(1, "days");
          }
          return dates;
        },
        padTo2Digits(num) {
          return String(num).padStart(2, '0');
        },
        getHeightRowGanttChart() {
          let height_row = 68;
          if (window.innerWidth == 1920 && window.innerHeight == 1080) {
            height_row = 70;
          }
          else if (window.innerWidth <= 1180 && window.innerHeight == 820 || window.innerWidth <= 1920 && window.innerHeight <= 976) {
            height_row = 50;
          }
          else if (window.innerHeight > 1080) {
            if (window.innerWidth == 2048 && window.innerHeight == 1536) {
              height_row = 110;
            } else {
              if (window.innerWidth == 3440 && window.innerHeight == 1440) {
                height_row = 75;
              } else {
                height_row =
                  68 * (window.innerHeight / 1080) +
                  (window.innerHeight - 1080) / 68;
              }
            }
          }
          return height_row;
        },
        handleCheckPermissionRole(role) {
          return store.getters.accessibleRoutes[role].action;
        },
      },
    });
  },
};
