<template>
  <v-app-bar app v-if="isLoggedIn" class="header-nav">
    <div class="d-flex align-center header-nav-link">
      <!-- <a href="/" class="header-nav-logo">
        <v-img
          alt="Panasonic Logo"
          class="nav-logo"
          contain
          src="@/assets/logo-KTC.png"
        />
      </a> -->
      <div class="header-route-page">
        <a @click="goBack">
          <v-img
            class="nav-back"
            contain
            src="@/assets/back-icon.svg"
          />
        </a>
        <h2 class="header-title">{{ $route.name }}</h2>
      </div>
    </div>
    <div class="app-header-spacing"></div>
    <template>
      <div class="menu-wrapper">
        <v-row no-gutters>
          <v-spacer />
          <div
            v-if="accessibleRoutes.dashboard.view"
            style="margin-right: 5px"
            class="menu-item-outside"
          >
            <div class="pd-left">
              <router-link :to="{ path: '/' }">
                <v-btn
                  :ripple="false"
                  icon
                  @click="active = 'home'"
                  :class="{ active: getActive() === 'home' }"
                >
                  DASHBOARD
                </v-btn>
              </router-link>
            </div>
          </div>
          <div
            v-if="accessibleRoutes.schedule.view"
            style="margin-right: 5px"
            class="menu-item-outside"
          >
            <div class="pd-left">
              <router-link :to="{ path: '/schedule' }">
                <v-btn
                  :ripple="false"
                  icon
                  @click="active = 'schedule'"
                  :class="{ active: getActive() === 'schedule' }"
                >
                  SCHEDULE
                </v-btn>
              </router-link>
            </div>
          </div>
          <div
            v-if="accessibleRoutes.usage_trend.view"
            style="margin-right: 5px"
            class="menu-item-outside"
          >
            <div class="pd-left">
              <router-link :to="{ path: '/energy-consumption' }">
                <v-btn
                  :ripple="false"
                  icon
                  @click="active = 'usage-trend'"
                  :class="{ active: getActive() === 'usage-trend' }"
                >
                  USAGE TREND
                </v-btn>
              </router-link>
            </div>
          </div>
          <div style="max-width: 80px; margin-right: 5px" class="burger-icon">
            <div class="pd-left">
              <div class="menu-wrapper-mobile">
                <v-menu offset-y :transition=false>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      type="button"
                      class="burger-button"
                      v-bind="attrs"
                      v-on="on"
                      title="Menu"
                    >
                    </v-btn>
                  </template>
                  <v-list
                    class="bar-menu-dropdown"
                  >
                    <v-list-item v-if="accessibleRoutes.dashboard.view" class="menu-item-inside" :class="{ active: getActive() === 'home' }">
                      <router-link :to="{ path: '/' }">
                        <span>
                          <i class="fa-solid fas fa-house mr-2"></i>Dashboard
                        </span>
                      </router-link>
                    </v-list-item>
                    <v-list-item v-if="accessibleRoutes.schedule.view" class="menu-item-inside" :class="{ active: getActive() === 'schedule' }">
                      <router-link :to="{ path: '/schedule' }">
                        <span>
                          <i class="fa-solid fas fa-clock mr-2"></i>Schedule
                        </span>
                      </router-link>
                    </v-list-item>
                    <v-list-item v-if="accessibleRoutes.usage_trend.view" class="menu-item-inside" :class="{ active: getActive() === 'usage-trend' }">
                      <router-link :to="{ path: '/energy-consumption' }">
                        <span>
                          <i class="fa-solid fas fa-chart-column mr-2"></i>Usage Trend
                        </span>
                      </router-link>
                    </v-list-item>
                    <v-list-item>
                      <a @click="modalChangePassword = true">
                        <span>
                          <i class="fa-solid fas fa-user mr-2"></i>My Profile
                        </span>
                      </a>
                    </v-list-item>
                    <v-list-item v-if="accessibleRoutes.user.view" :class="{ active: getActive() === 'reset-password' }">
                      <router-link :to="{ path: '/users/reset-password' }">
                        <span>
                          <i class="fa-solid fas fa-user-cog mr-2"></i>User Management
                        </span>
                      </router-link>
                    </v-list-item>
                    <!-- <v-list-item v-if="accessibleRoutes.setting.view && $store.state.user.screen === null">
                      <router-link :to="{ path: '/settings' }">
                        <span>
                          <i class="fa-solid fa-gear mr-2"></i>Dimming Setting
                        </span>
                      </router-link>
                    </v-list-item> -->
                    <!-- <v-list-item v-if="accessibleRoutes.log.view && $store.state.user.screen === null">
                      <router-link :to="{ path: '/logs/users' }">
                        <span>
                          <button class="icon-logs mr-2"></button>History Log
                        </span>
                      </router-link>
                    </v-list-item> -->
                    
                    <!-- <v-list-item v-if="accessibleRoutes.schedule.view && $store.state.user.screen === null">
                      <router-link :to="{ path: '/public-holiday' }">
                        <span>
                          <button class="fas fa-calendar-alt mr-2"></button
                          >Public Holiday
                        </span>
                      </router-link>
                    </v-list-item> -->
                    <v-list-item>
                      <a :ripple="false" @click="logout">
                        <span>
                          <i class="fa-solid fa-right-from-bracket mr-2"></i
                          >Logout
                        </span>
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </v-row>
      </div>
      <v-dialog
        v-model="modalChangePassword"
        persistent
        max-width="500"
        content-class="profile-confirm-dialog"
      >
        <v-card id="profile-confirm">
          <v-card-title class="text-center" style="display: block;">My Profile</v-card-title>
          <v-card-text class="change-password"
            ><v-row no-gutters>
              <v-col cols="12">
                <UserProfile @submit-success="closeModal"></UserProfile>
              </v-col> </v-row
          ></v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-app-bar>
</template>

<script>
import { auth } from "@/services";
import UserProfile from "@/components/UserProfile.vue";

export default {
  name: "App",
  components: { UserProfile },
  data() {
    return {
      active: "home",
      nowTime: "",
      nowDate: "",
      isBurgerActive: false,
      modalChangePassword: false,
    };
  },
  methods: {
    goBack(){
      if (this.$router.history) {
        this.$router.back();
      }
    },
    toggle() {
      this.isBurgerActive = !this.isBurgerActive;
    },
    logout() {
      auth.logout().finally(() => {
         window.location.href = "/login";
      });
    },
    getActive() {
      switch (window.location.pathname) {
        case "/":
          return "home";
        case "/users/list":
        case "/users/groups":
          return "user";
        case "/schedule":
          return "schedule";
        case "/energy-consumption":
          return "usage-trend";
        case "/settings":
          return "settings";
        case "/logs":
          return "logs";
        case "/users/reset-password":
          return "reset-password";
        default:
          return "home";
      }
    },
    closeModal() {
      this.modalChangePassword = false;
    },
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
  },
};
</script>

<style scoped>
.v-menu__content.menuable__content__active {
  top: 80px !important;
  min-width: 85px !important;
}

.bar-menu-dropdown .v-list-item a {
  color: #fff !important;
  text-decoration: none;
  font-weight: bold;
}

.bar-menu-dropdown {
  background: none !important;
  padding-top: 0;
  padding-bottom: 0;
  background-color: grey !important;
  border: none !important;
}

.v-list-item:hover, .v-list-item.active{
  background-color: black !important;
}

@media only screen and (max-width: 1200px) {
  .nav-logo {
    width: 70%;
    height: 70%;
  }
}

@media only screen and (max-width: 820px) {
  .v-menu__content.menuable__content__active {
    top: 65px !important;
  }
}

@media only screen and (max-width: 480px) {
  .v-menu__content.menuable__content__active {
    top: 55px !important;
  }
}
</style>
